.footer {
  background-color: #f8f8f8;
  padding: 20px;
  font-size: 12px; 
  letter-spacing: 0.05em;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.footer-columns {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-grow: 1;
}

.footer-column {
  flex: 1;
  min-width: 180px;
}

.footer-info {
  text-align: center; 
  max-width: 300px;
  flex-shrink: 0;
}

.footer-info p {
  margin-bottom: 20px;
  color: #333;
}

.social-icons {
  display: flex;
  gap: 10px;
  justify-content: center; 
}

.social-icons a {
  font-size: 20px; 
  color: #333;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #0077b5; 
}

.footer-column h3 {
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif; 
  font-weight: 500;
  font-size: 14px; 
  letter-spacing: 0.15em; 
  color: #333; 
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin: 5px 0;
  font-size: 12px;
}

.footer-column ul li a {
  text-decoration: none;
  color: #333;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.footer-info strong {
  font-weight: bold; 
  color: #333; 
}

.footer-stripe {
  background-color: #fff; 
  color: #000000; 
  padding: 5px 0; 
  font-size: 9px; 
  text-align: center;
}

.footer-links {
  display: flex; 
  justify-content: center; 
  gap: 15px;
  margin-bottom: 5px;
}

.footer-links a {
  color: #333;
  text-decoration: none; 
}

.footer-links a:hover {
  text-decoration: underline; 
}

.footer-copyright {
  margin: 0; 
}
