.login-banner {
  height: 80px;
  background-color: white;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 150px;
  position: relative;
}

.banner-left {
  display: flex;
  align-items: center;
}

.profile-icon {
  font-size: 40px;
  color: grey;
  margin-right: 15px;
}

.login-banner-text {
  font-size: 12px;
  color: black;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}

.bold-text {
  font-weight: bold;
}

.create-account-link {
  color: rgb(52, 79, 228);
  text-decoration: none;
  cursor: pointer;
}

.create-account-link:hover {
  color: black;
  text-decoration: none;
}

.login-banner-button {
  position: absolute;
  right: 20px;
  top: 22px;
  border: 1px solid black;
  border-radius: 20px;
  padding: 6px 20px;
  background-color: black;
  color: white;
  font-size: 0.7em;
  font-weight: bold;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.login-banner-button:hover {
  background-color: white;
  color: black;
  transform: scale(1.05);
}

@media (min-width: 1080px) {
  .login-banner {
    display: none;
  }
}
