/* Global Styles */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background-color: #ffffff;
}

/* Layout for app container */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Content wrapper */
.content-wrap {
  flex: 1;
  padding: 150px 0px;
  background-color: rgb(196, 202, 224);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Footer */
footer {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
  border-top: 1px solid #e0e0e0;
}

/* Link Styles */
a {
  color: #00b300;
  text-decoration: none;
  transition: color 0.2s ease;
}

a:hover {
  color: #990000;
  text-decoration: underline;
}

.clickable-card {
  cursor: pointer;
  background-color: rgb(137, 171, 204);
  padding: 15px;
  transition: transform 0.2s ease-in-out;
}

.card-title{
  color: white;
}

.card-text{
  color: #e5c07dff;
  font-size: 14px;
  }

.clickable-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Button styles */
.button {
  background-color: #00b300;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #009900;
}

/* Utility spacing */
.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
