.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  z-index: 20000;
  transition: opacity 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.modal-overlay.show {
  opacity: 1;
  visibility: visible;
}

.modal-content {
  width: 31%;
  height: calc(100vh - 15%);
  background: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15%;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.modal-content.slide-in {
  transform: translateX(0);
}

.modal-content.slide-out {
  transform: translateX(100%);
}

.modal-body {
  flex: 1;
  overflow-y: auto;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: auto;
}

.checkout-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.checkout-button:hover {
  background-color: #45a049;
}

.btn {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 48%;
}

.btn-close {
  background-color: #f44336;
  color: white;
}

.btn-checkout {
  background-color: #4CAF50;
  color: white;
}

.cart-items {
  list-style-type: none;
  padding: 0;
}

.cart-item {
  display: flex;
  margin-bottom: 15px;
}

.item-image {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.item-quantity-control {
  display: flex;
  align-items: center;
}

.quantity-btn {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quantity-btn:hover {
  background-color: #e0e0e0;
}
